var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "                        <input type=\"checkbox\" id=\"handout-all\" data-ui=\"handout-all\" class=\"form-control\">\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"handout-table-container\">\n    <table class=\"table table-vcenter handout-table\">\n        <thead>\n        <tr>\n            <th class=\"text-center shipped\">\n                <div class=\"shipped-container\">\n"
    + ((stack1 = require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/compare.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"mode") : depth0),"===","edit",{"name":"common$compare","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":20},"end":{"line":9,"column":39}}})) != null ? stack1 : "")
    + "                    <span>"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"give_out",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":10,"column":26},"end":{"line":10,"column":57}}}))
    + "</span>\n                </div>\n            </th>\n            <th class=\"text-center quantity\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"quantity",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":13,"column":45},"end":{"line":13,"column":76}}}))
    + "</th>\n            <th class=\"name\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"name",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":14,"column":29},"end":{"line":14,"column":56}}}))
    + "</th>\n            <th class=\"note\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"note",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":15,"column":29},"end":{"line":15,"column":56}}}))
    + "</th>\n        </tr>\n        </thead>\n        <tbody></tbody>\n    </table>\n</div>\n<div class=\"left-keypad\" data-ui=\"keypad\" style=\"display: none;\"></div>\n";
},"useData":true});
var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <tr class=\"payment-"
    + alias4(((helper = (helper = lookupProperty(helpers,"type") || (depth0 != null ? lookupProperty(depth0,"type") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"type","hash":{},"data":data,"loc":{"start":{"line":13,"column":27},"end":{"line":13,"column":35}}}) : helper)))
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"className") || (depth0 != null ? lookupProperty(depth0,"className") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"className","hash":{},"data":data,"loc":{"start":{"line":13,"column":36},"end":{"line":13,"column":49}}}) : helper)))
    + "\">\n            <th>"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":14,"column":16},"end":{"line":14,"column":24}}}) : helper)))
    + ":</th>\n            <td >"
    + alias4(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/currencyFormat.js").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"currency_iso3") : depths[1]),(depth0 != null ? lookupProperty(depth0,"value_wt") : depth0),{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":15,"column":17},"end":{"line":15,"column":68}}}))
    + "</td>\n        </tr>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n<h1>\n    "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"transaction_completed",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":3,"column":48}}}))
    + "\n    <small>#"
    + alias2(((helper = (helper = lookupProperty(helpers,"number") || (depth0 != null ? lookupProperty(depth0,"number") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"number","hash":{},"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":4,"column":24}}}) : helper)))
    + "</small>\n</h1>\n<table>\n    <tbody>\n    <tr>\n        <th>"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"total_sale",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":9,"column":12},"end":{"line":9,"column":45}}}))
    + ":</th>\n        <td >"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/currencyFormat.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"currency_iso3") : depth0),(depth0 != null ? lookupProperty(depth0,"value_wt") : depth0),{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":10,"column":13},"end":{"line":10,"column":61}}}))
    + "</td>\n    </tr>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"payments") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":4},"end":{"line":17,"column":13}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = container.invokePartial(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/shop.cash-register-retail/templates/success/loyaltyPointsTransaction.hbs"),depth0,{"name":"modules/shop.cash-register-retail/templates/success/loyaltyPointsTransaction","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </tbody>\n</table>\n";
},"usePartial":true,"useData":true,"useDepths":true});
var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<div class=\"dialog--header\">\n    <div class=\"alert alert-warning\">\n\n        <strong>"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"warning_exclamation",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":5,"column":16},"end":{"line":5,"column":58}}}))
    + "</strong> "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"all_items_from_the_order_are_already_processed_you_have_to_handle_the_rest_of_the_order_from_the_backoffice",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":5,"column":68},"end":{"line":5,"column":198}}}))
    + "\n    </div>\n    <button class=\"dialog--close btn btn-danger\" data-ui=\"close\"><i class=\"fa fa-times\"></i></button>\n</div>\n<div class=\"dialog--content\">\n    <div data-ui=\"message\" class=\"alert message--alert hidden\"></div>\n</div>\n";
},"3":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"dialog--header\">\n    <h2>"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"pickup_at_the_store",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":14,"column":8},"end":{"line":14,"column":50}}}))
    + " #"
    + alias2(((helper = (helper = lookupProperty(helpers,"number") || (depth0 != null ? lookupProperty(depth0,"number") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"number","hash":{},"data":data,"loc":{"start":{"line":14,"column":52},"end":{"line":14,"column":62}}}) : helper)))
    + "</h2>\n\n    <button class=\"dialog--close btn btn-danger\" data-ui=\"close\">\n        <i class=\"fa fa-times\"></i>\n    </button>\n</div>\n<div class=\"dialog--content\">\n    <div data-ui=\"content\"></div>\n"
    + ((stack1 = require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/compare.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"mode") : depth0),"==",(depth0 != null ? lookupProperty(depth0,"MODE_EDIT") : depth0),{"name":"common$compare","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.program(15, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":22,"column":4},"end":{"line":50,"column":23}}})) != null ? stack1 : "")
    + "</div>\n"
    + ((stack1 = require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/compare.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"mode") : depth0),"==",(depth0 != null ? lookupProperty(depth0,"MODE_EDIT") : depth0),{"name":"common$compare","hash":{},"fn":container.program(18, data, 0, blockParams, depths),"inverse":container.program(21, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":52,"column":0},"end":{"line":72,"column":19}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <h4>\n            <div class=\"pull-left\">\n                "
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"shipping_method",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":25,"column":16},"end":{"line":25,"column":54}}}))
    + ":\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasMultipleMethods") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.program(9, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":26,"column":16},"end":{"line":40,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n        </h4>\n";
},"5":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <select class=\"form-control\" data-ui=\"method\" data-input=\"shipping-method\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"methods") : depth0),{"name":"each","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":24},"end":{"line":30,"column":33}}})) != null ? stack1 : "")
    + "                    </select>\n";
},"6":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <option value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":29,"column":43},"end":{"line":29,"column":49}}}) : helper)))
    + "\" "
    + ((stack1 = require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/compare.js").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"shippingMethodId") : depths[1]),"==",(depth0 != null ? lookupProperty(depth0,"id") : depth0),{"name":"common$compare","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":51},"end":{"line":29,"column":125}}})) != null ? stack1 : "")
    + ">"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":29,"column":126},"end":{"line":29,"column":134}}}) : helper)))
    + "</option>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "selected";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"methodName") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(13, data, 0),"data":data,"loc":{"start":{"line":33,"column":20},"end":{"line":39,"column":27}}})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"shippingMethodIcon") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":34,"column":24},"end":{"line":34,"column":95}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"methodName") || (depth0 != null ? lookupProperty(depth0,"methodName") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"methodName","hash":{},"data":data,"loc":{"start":{"line":34,"column":96},"end":{"line":34,"column":110}}}) : helper)))
    + "\n";
},"11":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<i class=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"shippingMethodIcon") || (depth0 != null ? lookupProperty(depth0,"shippingMethodIcon") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"shippingMethodIcon","hash":{},"data":data,"loc":{"start":{"line":34,"column":60},"end":{"line":34,"column":82}}}) : helper)))
    + "\"></i>";
},"13":function(container,depth0,helpers,partials,data) {
    return "                        <span class=\"label label-danger\">\n                            "
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"no_pickup_methods_available",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":37,"column":28},"end":{"line":37,"column":78}}}))
    + "\n                        </span>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"hasReceiptPrinter") : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":44,"column":8},"end":{"line":49,"column":15}}})) != null ? stack1 : "");
},"16":function(container,depth0,helpers,partials,data) {
    return "        <label class=\"form-control\">\n            <input type=\"checkbox\"  checked data-ui=\"print-checkbox\">\n            <span class=\"h4\"> "
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"print_confirmation",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":47,"column":30},"end":{"line":47,"column":71}}}))
    + "</span>\n        </label>\n";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"dialog--footer\">\n        <button data-ui=\"confirm\" class=\"btn btn-lg btn-success\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"methodName") : depth0),{"name":"unless","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":54,"column":65},"end":{"line":54,"column":106}}})) != null ? stack1 : "")
    + ">\n            "
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"collect",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":55,"column":12},"end":{"line":55,"column":42}}}))
    + "\n        </button>\n    </div>\n";
},"19":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"dialog--footer three-buttons\">\n        <button data-ui=\"back\" class=\"btn btn-lg btn-default\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"methodName") : depth0),{"name":"unless","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":60,"column":62},"end":{"line":60,"column":103}}})) != null ? stack1 : "")
    + ">\n            "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"back_to_collection_screen",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":61,"column":12},"end":{"line":61,"column":60}}}))
    + "\n        </button>\n        <button data-ui=\"deliver\" class=\"btn btn-lg btn-success\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"methodName") : depth0),{"name":"unless","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":63,"column":65},"end":{"line":63,"column":106}}})) != null ? stack1 : "")
    + ">\n            <i class=\"fas fa-hand-holding-box\"></i>\n            "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"finalize_collection",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":65,"column":12},"end":{"line":65,"column":54}}}))
    + "\n        </button>\n        <button data-ui=\"prepare\" class=\"btn btn-lg btn-default\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"methodName") : depth0),{"name":"unless","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":67,"column":65},"end":{"line":67,"column":106}}})) != null ? stack1 : "")
    + ">\n            <i class=\"far fa-box\"></i>\n            "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"ready_for_customer_pickup",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":69,"column":12},"end":{"line":69,"column":60}}}))
    + "\n        </button>\n    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isEmpty") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(3, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":73,"column":7}}})) != null ? stack1 : "");
},"useData":true,"useDepths":true});
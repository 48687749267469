var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "                <span>"
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"no_receipt_copy",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":21,"column":22},"end":{"line":21,"column":60}}}))
    + "</span>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "                <span>"
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"no_receipt",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":23,"column":22},"end":{"line":23,"column":55}}}))
    + "</span>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showKitchenReceipt") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":8},"end":{"line":48,"column":15}}})) != null ? stack1 : "")
    + "\n        <button class=\"btn btn-light designer-box rounded\">\n            <div class=\"loader-wrapper\" data-name=\"loader-print\">\n                <div class=\"loader\"></div>\n            </div>\n            <div data-action=\"receipt\">\n                <div>\n                    <i class=\"fas fa-receipt\"></i>\n                </div>\n                <br>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"has_ccv_payment") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":59,"column":16},"end":{"line":63,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n        </button>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <button class=\"btn btn-light designer-box rounded\">\n                <div class=\"loader-wrapper\" data-name=\"loader-kitchen\">\n                    <div class=\"loader\"></div>\n                </div>\n                <div data-action=\"kitchen\">\n                    <div>\n                        <i class=\"fas fa-hat-chef\"></i>\n                    </div>\n                    <br>\n\n                    <span>"
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"print_kitchen_receipt",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":41,"column":26},"end":{"line":41,"column":70}}}))
    + "</span>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"has_ccv_payment") : depth0),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":20},"end":{"line":45,"column":31}}})) != null ? stack1 : "")
    + "                </div>\n            </button>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "                        <span>&</span>\n                        <span>"
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"print_receipt",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":44,"column":30},"end":{"line":44,"column":66}}}))
    + "</span>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "                    <span>"
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"print_receipt_copy",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":60,"column":26},"end":{"line":60,"column":67}}}))
    + "</span>\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "                    <span>"
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"print_receipt",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":62,"column":26},"end":{"line":62,"column":62}}}))
    + "</span>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isEmailFeatureAvailable") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":69,"column":8},"end":{"line":78,"column":15}}})) != null ? stack1 : "");
},"14":function(container,depth0,helpers,partials,data) {
    return "            <button class=\"btn btn-light designer-box rounded\" data-action=\"email\">\n                <div class=\"loader-wrapper\" data-name=\"loader-email\">\n                    <div class=\"loader\"></div>\n                </div>\n                <i class=\"fas fa-envelope\"></i>\n                <br>\n                <span>"
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"email_invoice",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":76,"column":22},"end":{"line":76,"column":58}}}))
    + "</span>\n            </button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"loader-wrapper\" data-name=\"loader-success\">\n    <div class=\"loader\"></div>\n</div>\n\n<div class=\"payment-info-left\">\n    <i class=\"far fa-5x fa-check-circle\"></i><br />\n</div>\n\n<div class=\"payment-info-right\" data-region=\"payments\">\n\n</div>\n\n<div class=\"btn-row\">\n    <button class=\"btn btn-light designer-box rounded\">\n        <div data-action=\"no-receipt\">\n            <div class=\"no-receipt-icons\">\n                <i class=\"fal fa-ban \"></i>\n            </div>\n            <br>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"has_ccv_payment") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":20,"column":12},"end":{"line":24,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n    </button>\n\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"has_cups_printer") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":4},"end":{"line":66,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_invoice_payment") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":68,"column":4},"end":{"line":79,"column":11}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});
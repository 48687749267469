var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "("
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"repaired_item_serial_no") || (depth0 != null ? lookupProperty(depth0,"repaired_item_serial_no") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"repaired_item_serial_no","hash":{},"data":data,"loc":{"start":{"line":15,"column":78},"end":{"line":15,"column":107}}}) : helper)))
    + ")";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/datetime.js").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"date_done") : depth0),"LL",{"name":"common$datetime","hash":{},"data":data,"loc":{"start":{"line":41,"column":16},"end":{"line":41,"column":50}}}))
    + "\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "                -\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"loader-wrapper\" data-name=\"loader-print\">\n            <div class=\"loader\"></div>\n        </div>\n        <button class=\"btn btn-light btn-block designer-box rounded\" data-ui=\"print-receipt\">"
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"print_receipt",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":73,"column":93},"end":{"line":73,"column":129}}}))
    + "</button>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "        <button class=\"btn btn-light btn-block designer-box rounded\" disabled>"
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"print_receipt",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":75,"column":78},"end":{"line":75,"column":114}}}))
    + "</button>\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"customer full-child-height\" data-region=\"customer\"></div>\n<div class=\"description designer-box rounded p-3\">\n    <div class=\"row\">\n        <div class=\"col-md-8\">\n            <p class=\"mb-0\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"description") || (depth0 != null ? lookupProperty(depth0,"description") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"description","hash":{},"data":data,"loc":{"start":{"line":5,"column":28},"end":{"line":5,"column":45}}}) : helper)))
    + "</p>\n        </div>\n        <div class=\"col-md-4\">\n            <button data-ui=\"moreDetails\" class=\"btn btn-info btn-block designer-box rounded\"><i class=\"fa fa-eye\"></i></button>\n        </div>\n    </div>\n    <hr class=\"mt-2 mb-3 mr-3\">\n    <p>"
    + alias4(((helper = (helper = lookupProperty(helpers,"extra_information") || (depth0 != null ? lookupProperty(depth0,"extra_information") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"extra_information","hash":{},"data":data,"loc":{"start":{"line":12,"column":7},"end":{"line":12,"column":30}}}) : helper)))
    + "</p>\n</div>\n<div class=\"special designer-box rounded p-3\">\n    <p class=\"mb-4\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"repaired_item_name") || (depth0 != null ? lookupProperty(depth0,"repaired_item_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"repaired_item_name","hash":{},"data":data,"loc":{"start":{"line":15,"column":20},"end":{"line":15,"column":44}}}) : helper)))
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"repaired_item_serial_no") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":45},"end":{"line":15,"column":115}}})) != null ? stack1 : "")
    + "</p>\n    <p>"
    + alias4(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"maximum_agreed_cost",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":16,"column":7},"end":{"line":16,"column":49}}}))
    + ": "
    + alias4(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/currencyFormat.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"currency_iso3") : depth0),(depth0 != null ? lookupProperty(depth0,"max_agreed_cost") : depth0),{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":16,"column":51},"end":{"line":16,"column":107}}}))
    + "</p>\n</div>\n<div class=\"info designer-box rounded\">\n    <div class=\"row\">\n        <div class=\"col-md-10\">\n            <h3>"
    + alias4(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"repair_{number}",depth0,{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":21,"column":16},"end":{"line":21,"column":57}}}))
    + "</h3>\n        </div>\n        <div class=\"col-md-2\">\n            <button class=\"btn btn-info btn-block designer-box rounded\" data-ui=\"logs\"><i class=\"fa fa-eye\"></i></button>\n        </div>\n    </div>\n    <div class=\"row\">\n        <label class=\"control-label vertical-align col-md-5\">\n            "
    + alias4(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"date_in",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":29,"column":12},"end":{"line":29,"column":42}}}))
    + ":\n        </label>\n        <div class=\"col-md-7 \">\n            "
    + alias4(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/datetime.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"date_created") : depth0),"LL",{"name":"common$datetime","hash":{},"data":data,"loc":{"start":{"line":32,"column":12},"end":{"line":32,"column":49}}}))
    + "\n        </div>\n    </div>\n    <div class=\"row\">\n        <label class=\"control-label vertical-align col-md-5\">\n            "
    + alias4(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"date_done",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":37,"column":12},"end":{"line":37,"column":44}}}))
    + ":\n        </label>\n        <div class=\"col-md-7 \">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"date_done") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":40,"column":12},"end":{"line":44,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n    </div>\n    <div class=\"row\">\n        <label class=\"control-label vertical-align col-md-5\">\n            "
    + alias4(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"status",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":49,"column":12},"end":{"line":49,"column":41}}}))
    + ":\n        </label>\n        <div class=\"col-md-7 \">\n            <span class=\"label label-"
    + alias4(((helper = (helper = lookupProperty(helpers,"statusClass") || (depth0 != null ? lookupProperty(depth0,"statusClass") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"statusClass","hash":{},"data":data,"loc":{"start":{"line":52,"column":37},"end":{"line":52,"column":52}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"statusTitle") || (depth0 != null ? lookupProperty(depth0,"statusTitle") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"statusTitle","hash":{},"data":data,"loc":{"start":{"line":52,"column":54},"end":{"line":52,"column":69}}}) : helper)))
    + "</span>\n        </div>\n    </div>\n    <div class=\"row\">\n        <label class=\"control-label vertical-align col-md-5\">\n            "
    + alias4(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"created_by",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":57,"column":12},"end":{"line":57,"column":45}}}))
    + ":\n        </label>\n        <div class=\"col-md-7 \">\n            "
    + alias4(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"created_by") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\n        </div>\n    </div>\n</div>\n<div class=\"product-list designer-box rounded product-list-checkout\" data-region=\"product-list-and-search\"></div>\n<div class=\"product-total full-child-height\" data-region=\"product-totals\"></div>\n<div class=\"keypad designer-box rounded\" data-region=\"keypad\"></div>\n<div class=\"pay full-child-height\" data-ui=\"checkoutButtons\">\n    <button class=\"btn btn-info btn-block designer-box rounded\" data-action=\"back\" data-ui=\"back\">"
    + alias4(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"back",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":68,"column":98},"end":{"line":68,"column":125}}}))
    + "</button>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"has_receipt_printer") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":69,"column":4},"end":{"line":76,"column":11}}})) != null ? stack1 : "")
    + "    <button "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isEditable") : depth0),{"name":"unless","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":77,"column":12},"end":{"line":77,"column":53}}})) != null ? stack1 : "")
    + " class=\"btn btn-success btn-block designer-box rounded\" data-action=\"finish\" data-ui=\"finish\"> "
    + alias4(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"finish",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":77,"column":148},"end":{"line":77,"column":177}}}))
    + "</button>\n</div>\n\n<div class=\"products-description designer-box rounded\" data-region=\"product-description\" style=\"display: none;\" data-ui=\"product-description\"></div>\n\n<div class=\"hidden\" data-region=\"popup\"></div>\n";
},"useData":true});
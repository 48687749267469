var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<div class=\"create-repair-container\">\n    <div class=\"form-wrapper designer-box rounded container-fluid\">\n        <div class=\"loader-wrapper\">\n            <div class=\"loader\"></div>\n        </div>\n        <h2>"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"create_a_new_repair",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":6,"column":12},"end":{"line":6,"column":54}}}))
    + "</h2>\n        <hr>\n        <form onsubmit=\"return false;\" method=\"post\">\n            <div class=\"form-group row\">\n                <div class=\"col-md-2\">\n                    <label class=\"control-label\">\n                        "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"customer",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":12,"column":24},"end":{"line":12,"column":55}}}))
    + ":\n                    </label>\n                </div>\n\n                <div class=\"form-control-static col-md-8\">\n                    <div data-region=\"customer-search-region\"></div>\n                    <input type=\"hidden\" name=\"relation_data_id\">\n                </div>\n\n                <div class=\"col-md-2\">\n                    <a href=\"javascript:void(0);\" data-action=\"create-customer\" class=\"btn btn-default btn-regular pull-right\">\n                        "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"create_customer",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":23,"column":24},"end":{"line":23,"column":62}}}))
    + "\n                    </a>\n                </div>\n            </div>\n            <div class=\"form-group row\">\n                <div class=\"col-md-2\">\n                    <label class=\"control-label\">\n                        "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"product",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":30,"column":24},"end":{"line":30,"column":54}}}))
    + ":\n                    </label>\n                </div>\n\n                <div class=\"form-control-static col-md-10\">\n                    <div id=\"select-a-product\" data-region=\"product-search-region\"></div>\n                    <input type=\"hidden\" name=\"repaired_item_name\">\n                </div>\n            </div>\n\n            <div class=\"form-group row\">\n                <div class=\"col-md-2\">\n                    <label class=\"control-label\">\n                        "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"product_serial",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":43,"column":24},"end":{"line":43,"column":61}}}))
    + ":\n                    </label>\n                </div>\n\n                <div class=\"form-control-static col-md-10\">\n                    <input type=\"text\" name=\"repaired_item_serial_no\" class=\"form-control\" id=\"product-serial\" placeholder=\""
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"product_serial",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":48,"column":124},"end":{"line":48,"column":161}}}))
    + "\">\n                </div>\n            </div>\n\n            <div class=\"form-group row\">\n                <div class=\"col-md-2\">\n                    <label class=\"control-label\">\n                        "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"issue",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":55,"column":24},"end":{"line":55,"column":52}}}))
    + ":\n                    </label>\n                </div>\n\n                <div class=\"form-control-static col-md-10\">\n                    <textarea class=\"form-control\" name=\"description\" id=\"description\" rows=\"10\" placeholder=\""
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"what_is_the_issue",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":60,"column":110},"end":{"line":60,"column":150}}}))
    + "\"></textarea>\n                </div>\n            </div>\n\n            <div data-region=\"repair-questions-region\">\n            </div>\n\n            <div class=\"form-group row\">\n                <div class=\"col-md-2\">\n                    <label class=\"control-label\">\n                        "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"due_date",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":70,"column":24},"end":{"line":70,"column":55}}}))
    + ":\n                    </label>\n                </div>\n\n                <div class=\"form-control-static col-md-10\">\n                    <h4>\n                        <a href=\"javascript:void(0);\" class=\"text-dark\" id=\"day-picker\">\n                            "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"select_a_date",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":77,"column":28},"end":{"line":77,"column":64}}}))
    + ": <span id=\"selected-date\"></span>\n                            <i class=\"fa fa-caret-down\"></i>\n                        </a>\n                    </h4>\n                    <input type=\"hidden\" name=\"agreed_delivery_date\">\n                </div>\n            </div>\n            <div class=\"form-group row\">\n                <div class=\"col-md-2\">\n                    <label class=\"control-label\">\n                        "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"maximum_agreed_cost",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":87,"column":24},"end":{"line":87,"column":66}}}))
    + ":\n                    </label>\n                </div>\n\n                <div class=\"form-control-static col-md-10\">\n                    <input type=\"number\" name=\"max_agreed_cost\" class=\"form-control\" id=\"maximum-agreed-cost\" placeholder=\""
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"maximum_agreed_cost",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":92,"column":123},"end":{"line":92,"column":165}}}))
    + "\">\n                </div>\n            </div>\n            <div class=\"form-group row\">\n                <div class=\"col-md-2\">\n                    <label class=\"control-label\">\n                        "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"handling_employee",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":98,"column":24},"end":{"line":98,"column":64}}}))
    + ":\n                    </label>\n                </div>\n\n                <div class=\"form-control-static col-md-10\">\n                    <div data-region=\"employee-search-region\"></div>\n                    <input type=\"hidden\" name=\"handling_employee_id\">\n                </div>\n            </div>\n            <div class=\"form-group row mt-5\">\n                <div class=\"col-md-6\">\n                    <a class=\"btn btn-default btn-regular pull-left\" data-action=\"back\">\n                        <i class=\"fas fa-arrow-left\"></i> "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"go_back",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":110,"column":58},"end":{"line":110,"column":88}}}))
    + "\n                    </a>\n                </div>\n                <div class=\"col-md-6\">\n                    <a class=\"btn btn-default btn-regular pull-right\" data-action=\"create-repair\">\n                        "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"create_repair",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":115,"column":24},"end":{"line":115,"column":60}}}))
    + "\n                    </a>\n                </div>\n            </div>\n        </form>\n    </div>\n</div>\n";
},"useData":true});
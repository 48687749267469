var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"form-group row\">\n    <div class=\"col-md-2\">\n        <label class=\"control-label\">\n            "
    + alias2(((helper = (helper = lookupProperty(helpers,"question") || (depth0 != null ? lookupProperty(depth0,"question") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"question","hash":{},"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":4,"column":26}}}) : helper)))
    + "\n        </label>\n    </div>\n\n    <div class=\"form-control-static col-md-10\">\n        <input id=\"answer\" class=\"form-control\" placeholder=\""
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"answer",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":9,"column":61},"end":{"line":9,"column":90}}}))
    + "\">\n    </div>\n</div>\n";
},"useData":true});
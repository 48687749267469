var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <button class=\"btn btn-light designer-box rounded\">\n            <div class=\"loader-wrapper\" data-name=\"loader-print\">\n                <div class=\"loader\"></div>\n            </div>\n            <div data-action=\"receipt\">\n                <i class=\"fas fa-receipt\"></i>\n                <br>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"has_ccv_payment") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":27,"column":16},"end":{"line":31,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n        </button>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "                    <span>"
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"print_receipt_copy",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":28,"column":26},"end":{"line":28,"column":67}}}))
    + "</span>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "                    <span>"
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"print_receipt",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":30,"column":26},"end":{"line":30,"column":62}}}))
    + "</span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"payment-info-left\">\n    <i class=\"far fa-5x fa-check-circle\"></i><br />\n</div>\n\n<div class=\"payment-info-right\" data-region=\"payments\"></div>\n\n<div class=\"btn-row\">\n    <button class=\"btn btn-light designer-box rounded\">\n        <div data-action=\"back\">\n            <div class=\"no-receipt-icons\">\n                <i class=\"fas fa-arrow-left\"></i>\n            </div>\n            <br>\n            <span>"
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"back",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":14,"column":18},"end":{"line":14,"column":45}}}))
    + "</span>\n        </div>\n    </button>\n\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"has_receipt_printer") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":4},"end":{"line":34,"column":11}}})) != null ? stack1 : "")
    + "\n</div>\n";
},"useData":true});
var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<div class=\"search-container designer-box rounded full-width\">\n    <form class=\"form\" onsubmit=\"return false;\" data-action=\"form\">\n        <div class=\"input-group\">\n            <input type=\"text\" class=\"form-control\" placeholder=\""
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"type_here_to_search_for_repair",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":4,"column":65},"end":{"line":4,"column":118}}}))
    + "\" data-action=\"search\" data-ui=\"search\">\n            <span class=\"input-group-btn\">\n                <button class=\"btn btn-regular btn-primary\" data-action=\"clear\" type=\"button\">\n                    "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"search_again",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":7,"column":20},"end":{"line":7,"column":55}}}))
    + "\n                </button>\n                <button class=\"btn btn-regular btn-info\" data-action=\"create\" type=\"button\">\n                    <i class=\"fas fa-plus\"></i> "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"new_repair",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":10,"column":48},"end":{"line":10,"column":81}}}))
    + "\n                </button>\n            </span>\n        </div>\n    </form>\n</div>\n\n<div>\n    <div class=\"loader-wrapper\" data-name=\"main\"><div class=\"loader\"></div></div>\n    <div class=\"product-container designer-box rounded\">\n        <div data-region=\"items\"></div>\n\n        <div class=\"more full-child-height\">\n            <button class=\"btn btn-primary btn-block\" data-action=\"more\" data-ui=\"more\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"load_more",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":23,"column":88},"end":{"line":23,"column":120}}}))
    + "</button>\n            <div class=\"loader-wrapper\" data-name=\"more\"><div class=\"loader\"></div></div>\n        </div>\n    </div>\n</div>\n";
},"useData":true});
var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "        <i class=\"fas fa-check fa-2x text-info\"></i>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "        <i class=\"fas fa-times fa-2x text-info\"></i>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"to_be_shipped_quantity") || (depth0 != null ? lookupProperty(depth0,"to_be_shipped_quantity") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"to_be_shipped_quantity","hash":{},"data":data,"loc":{"start":{"line":10,"column":8},"end":{"line":10,"column":34}}}) : helper)))
    + "\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "        0\n";
},"9":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <br>"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"sku",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":20,"column":12},"end":{"line":20,"column":38}}}))
    + ": "
    + alias2(((helper = (helper = lookupProperty(helpers,"sku") || (depth0 != null ? lookupProperty(depth0,"sku") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"sku","hash":{},"data":data,"loc":{"start":{"line":20,"column":40},"end":{"line":20,"column":47}}}) : helper)))
    + "\n";
},"11":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <small>\n            <br>+ "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"name","hash":{},"data":data,"loc":{"start":{"line":24,"column":18},"end":{"line":24,"column":26}}}) : helper)))
    + "\n        </small>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <em style=\"white-space: pre-line;\">"
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"serial_numbers",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":29,"column":43},"end":{"line":29,"column":81}}}))
    + ":</em>\n        <ul>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"to_be_shipped_serial_nos") : depth0),{"name":"each","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":12},"end":{"line":33,"column":21}}})) != null ? stack1 : "")
    + "        </ul>\n";
},"14":function(container,depth0,helpers,partials,data) {
    return "                <li>"
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "</li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<td class=\"text-center shipped\">\n"
    + ((stack1 = require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/compare.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"to_be_shipped_quantity") : depth0),">",0,{"name":"common$compare","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":6,"column":23}}})) != null ? stack1 : "")
    + "</td>\n<td class=\"text-center quantity\">\n"
    + ((stack1 = require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/compare.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"to_be_shipped_quantity") : depth0),">",0,{"name":"common$compare","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":9,"column":4},"end":{"line":13,"column":23}}})) != null ? stack1 : "")
    + "</td>\n<td class=\"name\">\n    <strong>\n        "
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":17,"column":8},"end":{"line":17,"column":16}}}) : helper)))
    + "\n    </strong>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showSku") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":4},"end":{"line":21,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"sub_items") : depth0),{"name":"each","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":4},"end":{"line":26,"column":13}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"to_be_shipped_serial_nos") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":4},"end":{"line":35,"column":11}}})) != null ? stack1 : "")
    + "</td>\n<td class=\"note\">\n    "
    + alias4(((helper = (helper = lookupProperty(helpers,"description") || (depth0 != null ? lookupProperty(depth0,"description") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"description","hash":{},"data":data,"loc":{"start":{"line":38,"column":4},"end":{"line":38,"column":19}}}) : helper)))
    + "\n</td>\n";
},"useData":true});
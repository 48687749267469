var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "15%";
},"3":function(container,depth0,helpers,partials,data) {
    return "20%";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<table class=\"table table-vcenter table-striped\">\n    <thead>\n    <tr>\n        <th class=\"text-left\" style=\"width: 30%;\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"number",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":4,"column":50},"end":{"line":4,"column":79}}}))
    + "</th>\n        <th class=\"text-center\" style=\"width: "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasReceiptPrinter") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":5,"column":46},"end":{"line":5,"column":92}}})) != null ? stack1 : "")
    + ";\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"value",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":5,"column":95},"end":{"line":5,"column":123}}}))
    + "</th>\n        <th class=\"text-center\" style=\"width: "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasReceiptPrinter") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":6,"column":46},"end":{"line":6,"column":92}}})) != null ? stack1 : "")
    + ";\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"status",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":6,"column":95},"end":{"line":6,"column":124}}}))
    + "</th>\n        <th class=\"text-center\" style=\"width: "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasReceiptPrinter") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":7,"column":46},"end":{"line":7,"column":92}}})) != null ? stack1 : "")
    + ";\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"description",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":7,"column":95},"end":{"line":7,"column":129}}}))
    + "</th>\n        <th style=\"width: 10%;\"></th>\n    </tr>\n    </thead>\n    <tbody></tbody>\n</table>\n";
},"useData":true});
var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"loyaltyPayment") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":19,"column":11}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <tr class=\"payment-loyalty-points\">\n            <th>"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"gained_loyalty_points_with_transaction",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":4,"column":16},"end":{"line":4,"column":77}}}))
    + ":</th>\n            <td >"
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"loyaltyPayment") : depth0)) != null ? lookupProperty(stack1,"loyaltyPointsGained") : stack1), depth0))
    + "</td>\n        </tr>\n        <tr class=\"payment-loyalty-points\">\n            <th>"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"used_loyalty_points_with_transaction",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":8,"column":16},"end":{"line":8,"column":75}}}))
    + ":</th>\n            <td >"
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"loyaltyPayment") : depth0)) != null ? lookupProperty(stack1,"loyaltyPointsSpent") : stack1), depth0))
    + "</td>\n        </tr>\n        <tr class=\"payment-loyalty-points\">\n            <th>"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"current_loyalty_points_balance",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":12,"column":16},"end":{"line":12,"column":69}}}))
    + ":</th>\n            <td >"
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"loyaltyPayment") : depth0)) != null ? lookupProperty(stack1,"loyaltyPointsNewBalance") : stack1), depth0))
    + "</td>\n        </tr>\n        <tr class=\"payment-loyalty-points\">\n            <th>"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"current_loyalty_points_exchange_value",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":16,"column":16},"end":{"line":16,"column":76}}}))
    + ":</th>\n            <td >"
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"loyaltyPayment") : depth0)) != null ? lookupProperty(stack1,"loyaltyPointsExchangeValue") : stack1), depth0))
    + "</td>\n        </tr>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isLoyaltyFeatureEnabled") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":20,"column":7}}})) != null ? stack1 : "");
},"useData":true});